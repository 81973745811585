<template>
  <div>
    <a-card title="入库通知单">
      <a-row :gutter="16">
        <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" placeholder="仓库" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <client-select v-model="searchForm.client" placeholder="客户" @change="search"></client-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select placeholder="状态" allowClear style="width: 100%" @change="changeStatus">
            <a-select-option key="in_progress" value="in_progress">进行中</a-select-option>
            <a-select-option key="completed" value="completed">已完成</a-select-option>
            <a-select-option key="voided" value="voided">已作废</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 260px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="单据编号, 客户单号, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 260px; margin-bottom: 12px">
          <a-input
            v-model="searchForm.fuzzy_search"
            style="border: 1px solid red; border-radius: 4px; margin: -1px 0"
            placeholder="模糊查询"
            allowClear
            @pressEnter="search"
          />
        </a-col>
        <a-col :span="24" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24" style="max-width: 680px; margin-bottom: 12px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">导入</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">导出</a-button>
            <a-button @click="printReceiptOrder">打印入库单</a-button>
            <a-button @click="printShelveOrder">打印上架单</a-button>
            <a-button @click="summaryExport">汇总导出</a-button>
            <a-popconfirm title="确定删除吗?" @confirm="bulkDelete">
              <a-button type="danger" icon="delete">批量删除</a-button>
            </a-popconfirm>
          </a-button-group>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="addReceiptOrder">新增入库通知单</a-button>
        </div>
      </a-row>

      <a-row>
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :scroll="{ x: 1900, y: 480 }"
          @change="tableChange"
        >
          <template slot="number" slot-scope="value, item, index">
            <a @click="detail(item)">{{ value }}</a>
          </template>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button @click="detail(item)">详情</a-button>
              <a-button type="primary" @click="copyOrder(item)">复制</a-button>
              <a-popconfirm title="确定强制完成吗?" @confirm="complete(item)">
                <a-button type="primary">强制完成</a-button>
              </a-popconfirm>
              <a-popconfirm title="确定作废重建吗?" @confirm="voidAndRecreate(item)">
                <a-button type="danger">作废重建</a-button>
              </a-popconfirm>
              <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
                <a-button type="danger">作废</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { receiptOrderTemplate, receiptOrderImport } from "@/api/import";
import {
  receiptOrderList,
  receiptOrderVoid,
  receiptOrderBulkDelete,
  receiptOrderPrint,
  shelveOrderPrint,
  receiptOrderComplete,
} from "@/api/stockIn";
import { receiptOrdersExport, receiptOrderSummaryExport } from "@/api/export";
import { warehouseOption } from "@/api/option";
import { exportExcel } from "@/utils/excel";
import { columns } from "./columns.js";
import moment from "moment";
import { getLodop } from "@/utils/LodopFuncs";
import NP from "number-precision";
import JsBarcode from "jsbarcode";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", page: 1, page_size: 48, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 48 },
      loading: false,
      items: [],
      warehouseItems: [],

      visible: false,
      targetItem: {},
      form: {},

      selectedRowKeys: [],
      selectionRows: [],
      importLoading: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();
      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      receiptOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.list();
    },
    changeStatus(value) {
      if (value === "in_progress") {
        this.searchForm.is_void = false;
        this.searchForm.is_completed = false;
      } else if (value === "completed") {
        this.searchForm.is_void = false;
        this.searchForm.is_completed = true;
      } else if (value === "voided") {
        this.searchForm.is_void = true;
        this.searchForm.is_completed = undefined;
      }
      this.search();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    addReceiptOrder() {
      this.$router.push({ path: "/stock_in/receipt_order_create" });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    detail(item) {
      this.$router.push({ path: "/stock_in/receipt_order_detail", query: { id: item.id } });
    },
    voidItem(item) {
      receiptOrderVoid({ id: item.id }).then(() => {
        this.$message.success("作废成功");
        this.list();
      });
    },
    voidAndRecreate(item) {
      receiptOrderVoid({ id: item.id }).then(() => {
        this.$message.success("作废成功");
        this.$router.push({ path: "/stock_in/receipt_order_create", query: { id: item.id } });
      });
    },
    copyOrder(item) {
      this.$router.push({ path: "/stock_in/receipt_order_create", query: { id: item.id } });
    },
    complete(item) {
      receiptOrderComplete({ id: item.id }).then(() => {
        this.$message.success("完成成功");
        this.list();
      });
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的入库通知单!");
        return false;
      }
      receiptOrdersExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, "入库通知单");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    summaryExport() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的入库通知单!");
        return false;
      }
      receiptOrderSummaryExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, "入库通知单汇总");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      receiptOrderTemplate()
        .then((resp) => {
          exportExcel(resp, "入库通知单导入模板");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        receiptOrderImport(data)
          .then(() => {
            this.$message.success("导入成功");
            this.list();
          })
          .catch((err) => {
            alert("导入错误:\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    bulkDelete() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要删除的入库通知单!");
        return false;
      }

      receiptOrderBulkDelete({ ids: this.selectedRowKeys }).then(() => {
        this.$message.success("批量删除成功");
        this.list();
      });
    },
    printText(value) {
      return value != null || value != undefined ? value : "";
    },
    printReceiptOrder() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要打印的入库通知单");
        return false;
      }

      receiptOrderPrint({ ids: this.selectedRowKeys }).then((data) => {
        const LODOP = getLodop();
        LODOP.PRINT_INIT("Receipt order");
        LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        for (let receiptOrderItem of data) {
          let canvas = document.createElement("canvas");
          JsBarcode(canvas, receiptOrderItem.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
          let barcodeBase64 = canvas.toDataURL();

          let totalQuantity = 0;
          for (let item of receiptOrderItem.receipt_material_items) {
            totalQuantity = NP.plus(totalQuantity, item.total_quantity);
          }

          let pages = Math.ceil(receiptOrderItem.receipt_material_items.length / 14);
          for (let index = 1; index <= pages; index++) {
            let items = receiptOrderItem.receipt_material_items.slice((index - 1) * 14, index * 14);
            let tableRows = items.map((item) => {
              return `
<tr>
  <td>${this.printText(item.material_item.number)}</td>
  <td>${this.printText(item.material_item.name)}</td>
  <td>${this.printText(item.material_item.english_name)}</td>
  <td>${this.printText(item.material_item.barcode)}</td>
  <td>${this.printText(item.total_quantity)}</td>
  <td>${this.printText(item.batch_number)}</td>
  <td></td>
</tr>`;
            });

            let pageContent = `
<div style="padding: 8px">
  <div style="font-size: 36px; text-align: center">
    <span>入库通知单</span>
    <span style="float: right;">${pages > 1 ? index : ""}</span>
  </div>

  <div style="margin-top: 8px; text-align: right">
    <img src="${barcodeBase64}" />
  </div>

  <div style="margin-top: 8px; display: flex">
    <div style="width: 33%">客户: ${this.printText(receiptOrderItem.client_item.name)}</div>
    <div style="width: 34%">仓库: ${this.printText(receiptOrderItem.warehouse_item.name)}</div>
    <div style="width: 33%">库区: ${this.printText(receiptOrderItem.area_name)}</div>
  </div>

  <div style="margin-top: 8px; display: flex">
    <div style="width: 33%">客户单号: ${this.printText(receiptOrderItem.external_number)}</div>
    <div style="width: 34%">创建人: ${this.printText(receiptOrderItem.creator_item?.name)}</div>
    <div style="width: 33%">创建时间: ${this.printText(receiptOrderItem.create_time)}</div>
  </div>

  <div style="margin-top: 8px; display: flex">
    <div style="width: 100%">备注: ${this.printText(receiptOrderItem.remark)}</div>
  </div>

  <div style="margin-top: 16px; font-size: 14px">
    <table border="1" cellspacing="0" style="width: 100%; text-align: center">
      <tr>
        <th>产品编号</th>
        <th>产品名称</th>
        <th>英文名称</th>
        <th>产品条码</th>
        <th>应收数量</th>
        <th>批次编号</th>
        <th>推荐库位</th>
      </tr>
      ${tableRows.join("")}
      <tr>
        <td>合计:</td>
        <td></td>
        <td></td>
        <td></td>
        <td>${this.printText(totalQuantity)}</td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>

  <div style="margin-top: 24px; font-size: 14px">
    收货人:
  </div>
  <div style="margin-top: 24px; font-size: 14px">
    收货日期:
  </div>
</div>`;

            LODOP.NewPage();
            LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
          }
        }

        LODOP.PREVIEW();
      });
    },
    printShelveOrder() {
      const shelveOrderIds = [];
      for (const item of this.items) {
        if (this.selectedRowKeys.indexOf(item.id) !== -1) {
          shelveOrderIds.push(item.shelve_order);
        }
      }

      if (!shelveOrderIds.length) {
        this.$message.warning("请选择要打印的上架单");
        return false;
      }

      shelveOrderPrint({ ids: shelveOrderIds }).then((data) => {
        const LODOP = getLodop();
        LODOP.PRINT_INIT("Receipt order");
        LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        for (let shelveOrderItem of data) {
          let canvas = document.createElement("canvas");
          JsBarcode(canvas, shelveOrderItem.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
          let barcodeBase64 = canvas.toDataURL();

          let totalQuantity = 0;
          for (let item of shelveOrderItem.shelve_material_items) {
            totalQuantity = NP.plus(totalQuantity, item.total_quantity);
          }

          let pages = Math.ceil(shelveOrderItem.shelve_material_items.length / 14);
          for (let index = 1; index <= pages; index++) {
            let items = shelveOrderItem.shelve_material_items.slice((index - 1) * 14, index * 14);
            let tableRows = items.map((item) => {
              return `
<tr>
  <td>${this.printText(item.material_item.number)}</td>
  <td>${this.printText(item.material_item.name)}</td>
  <td>${this.printText(item.material_item.barcode)}</td>
  <td>${this.printText(item.total_quantity)}</td>
  <td>${this.printText(item.batch_item.number)}</td>
  <td>${item.recommend_areas.join(", ")}</td>
</tr>`;
            });

            let pageContent = `
<div style="padding: 8px">
  <div style="font-size: 36px; text-align: center">
    <span>上架单</span>
    <span style="float: right;">${pages > 1 ? index : ""}</span>
  </div>

  <div style="margin-top: 8px; text-align: right">
    <img src="${barcodeBase64}" />
  </div>

  <div style="margin-top: 8px; display: flex">
    <div style="width: 50%">客户: ${this.printText(shelveOrderItem.client_item.name)}</div>
    <div style="width: 50%">仓库: ${this.printText(shelveOrderItem.warehouse_item.name)}</div>
  </div>

  <div style="margin-top: 8px; display: flex">
    <div style="width: 50%">创建人: ${this.printText(shelveOrderItem.creator_item?.name)}</div>
    <div style="width: 50%">创建时间: ${this.printText(shelveOrderItem.create_time)}</div>
  </div>

  <div style="margin-top: 16px; font-size: 14px">
    <table border="1" cellspacing="0" style="width: 100%; text-align: center">
      <tr>
        <th>产品编号</th>
        <th>产品名称</th>
        <th>产品条码</th>
        <th>上架数量</th>
        <th>批次编号</th>
        <th>推荐库区</th>
      </tr>
      ${tableRows.join("")}
      <tr>
        <td>合计:</td>
        <td></td>
        <td></td>
        <td>${this.printText(totalQuantity)}</td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</div>`;

            LODOP.NewPage();
            LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
          }
        }

        LODOP.PREVIEW();
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
