export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "入库通知单号",
    dataIndex: "number",
    width: 180,
    ellipsis: true,
    sorter: true,
    scopedSlots: { customRender: "number" },
  },
  {
    title: "状态",
    dataIndex: "status",
    width: 80,
    ellipsis: true,
    customRender: (value, item, index) => {
      return item.is_void ? "已作废" : item.is_completed ? "已完成" : "进行中";
    },
  },
  {
    title: "仓库名称",
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "订单类型",
    dataIndex: "order_type_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "客户单号",
    dataIndex: "external_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "客户",
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "到货日期",
    dataIndex: "arrival_date",
    width: 180,
    ellipsis: true,
  },
  {
    title: "创建人",
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: "备注",
    dataIndex: "remark",
    width: 240,
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 300,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
